<template>
  <div class="test">
    <div class="item">
      <van-button @click="setPageData">
         <van-icon :name="pageData ? 'success':'cross'" /></van-button>页面数据监测LOG
    </div>
    <div class="item" @click="setHttpPost">
      <van-button> <van-icon :name="httpPost ? 'success':'cross'" /></van-button>网络请求数据LOG
    </div>
    <div class="item" @click="setVoiceSlider">
      <van-button> <van-icon :name="voiceSlider ? 'success':'cross'" /></van-button>声音Slider是否可以拖动
    </div>
    <div class="item" @click="setVoiceSlider">
      <van-button> <van-icon :name="voiceSlider ? 'success':'cross'" /></van-button>刷新不返回首页
    </div>
    <van-field v-model="phone" placeholder="格式 +86-13312341234">
    </van-field>
    <div style="margin-top: 20px;">
      <van-button
        @click="addPhone" :disabled="!this.phone" type="primary" style="margin-right: 20px;">
        新增手机账号 验证码 8888
      </van-button>
      <van-button @click="clearMsg" :disabled="!this.phone" type="primary">
        删除该手机账号开户记录
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button, Icon, Field } from 'vant';
import { onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'Home',
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Field.name]: Field,
  },
  data() {
    return {
      phone: '',
      pageData: localStorage.getItem('page-data'),
      httpPost: localStorage.getItem('http-post'),
      voiceSlider: localStorage.getItem('voice-slider'),
    };
  },
  setup() {
    onMounted(() => {
      const store = useStore();
      store.commit('setTitleValue', '测试管理');
      store.commit('setStepsShow', false);
    });
  },
  methods: {
    addPhone() {
      const result = this.$api.addNewAccount({ mobile: this.phone, vcode: '8888' });
      if (result && result.code === 200) {
        this.$toast('新增成功');
      }
    },
    clearMsg() {
      const result = this.$api.addNewAccount({ mobile: this.phone });
      if (result && result.code === 200) {
        this.$toast('删除成功');
      }
    },
    setPageData() {
      const value = localStorage.getItem('page-data');
      if (value) {
        localStorage.removeItem('page-data');
        this.pageData = false;
        this.$toast('关闭 页面数据监测LOG');
      } else {
        localStorage.setItem('page-data', true);
        this.$toast('开启 页面数据监测LOG');
        this.pageData = true;
      }
    },
    random() {
      const prelist = ['130', '131', '132', '133', '134', '135', '136', '137', '138', '139',
        '147', '150', '151', '152', '153', '155', '156', '157', '158', '159',
        '186', '187', '188', '189'];
      const value = (new Array(8)).fill(0).map(() => Math.floor(Math.random() * 10)).join('');
      const before = prelist[Math.floor(Math.random() * prelist.length)];
      this.phone = before + value;
    },
    setHttpPost() {
      const value = localStorage.getItem('http-post');
      if (value) {
        localStorage.removeItem('http-post');
        this.$toast('关闭 网络请求数据LOG');
        this.httpPost = false;
      } else {
        localStorage.setItem('http-post', true);
        this.$toast('开启 网络请求数据LOG');
        this.httpPost = true;
      }
    },
    setVoiceSlider() {
      const value = localStorage.getItem('voice-slider');
      if (value) {
        localStorage.removeItem('voice-slider');
        this.$toast('关闭 声音Slider是否可以拖动');
        this.voiceSlider = false;
      } else {
        localStorage.setItem('voice-slider', true);
        this.$toast('开启 声音Slider是否可以拖动');
        this.voiceSlider = true;
      }
    },
  },
};
</script>

<style lang="less">
  .test {
    .item {
      padding: @space-0;
      text-align: left;
      display: flex;
      justify-content: flex-left;
      align-items: center;
    }
    .button {
      margin-right: 20px;
      font-size: 10px;
      border-radius: 50%;
    }
    .van-icon-success {
      color: green;
      transform: scale(2);
    }
    .van-icon-cross {
      color: red;
      transform: scale(2);
    }
  }

</style>
